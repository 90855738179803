<template>
    <div class="flex flex-1 flex-col lg:flex-row flex-wrap">
        <!-- sidebar -->

        <div class="sidebar w-full md:w-auto bg-white p-6 pt-0">
            <!-- lg:w-1/3 xl:w-1/5 -->
            <div class="sticky top-28 bottom-0">
                <div class="bg-white mt-8">
                    <a @click="selectCategory(0, '')" class="text-black cursor-pointer">
                        <h1 class="inline text-3xl font-bold">Photos</h1>
                    </a>
                    <span v-if="categoryName">
                        <span class="mx-2"> > </span>
                        <h2 class="inline text-2xl text-gray-800">{{ categoryName }}</h2>
                    </span>
                </div>

                <div class="mt-5 flex flex-row justify-between" style="min-width: 240px; max-width: 310px">
                    <div class="flex flex-col items-center mx-2 p-1 relative cursor-pointer" v-for="tag in rootTags"
                        v-bind:key="tag.id" @click="selectCategory(tag.id, tag.name)"
                        :class="activeCategoryClasses(tag.id)">
                        <svg v-if="tag.name == 'Nature'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"
                            class="w-10 inline fill-current stroke-current">
                            <path
                                d="M369 626.6c22 0 41-4.4 52.4-7.8l61.3 61.3v130.5a17.3 17.3 0 0034.6 0V680l61.3-61.3c11.4 3.4 30.4 7.8 52.4 7.8 29.6 0 64.8-8 94.1-37.4 60.6-60.6 57.2-176.9 57-181.8a17.3 17.3 0 00-16.7-16.7c-4.9-.2-121.2-3.6-181.8 57-51.1 51.1-37.5 119.7-29.5 146.5L517.3 631V470.1C541.9 456.9 600 418 600 345.7c0-85.8-84.6-165.5-88.2-168.9-6.6-6.2-17-6.2-23.6 0-3.6 3.4-88.2 83.1-88.2 168.9 0 72.3 58.1 111.2 82.7 124.5V631l-36.8-36.8c8-26.7 21.6-95.4-29.5-146.5-60.6-60.6-176.9-57.2-181.8-57-9.1.3-16.4 7.6-16.7 16.7-.2 4.9-3.6 121.2 57 181.8a129.6 129.6 0 0094.1 37.4zm239.1-154.3c37.8-37.8 107.1-45.3 139.1-46.7-1.4 32-8.9 101.4-46.7 139.1-29.4 29.4-67.9 29.6-93.3 25.3l74.8-74.8a17.3 17.3 0 000-24.5 17.3 17.3 0 00-24.5 0l-74.7 74.7c-4.1-25.5-3.8-64 25.3-93.1zM434.6 345.7c0-53.4 43.8-107.8 65.4-131.4 21.6 23.5 65.4 77.8 65.4 131.4 0 41.6-27.1 68.9-48.1 83.8V324.4a17.3 17.3 0 00-34.6 0v104.9c-21-15-48.1-42.4-48.1-83.6zm-42.7 126.6c29.4 29.4 29.6 67.9 25.3 93.3l-74.8-74.8a17.3 17.3 0 00-24.5 0 17.3 17.3 0 000 24.5l74.7 74.7c-25.4 4.2-64 4-93.1-25.2-37.8-37.8-45.3-107.1-46.7-139.1 32 1.3 101.3 8.8 139.1 46.6z" />
                            <path
                                d="M972.3 0H27.7A27.7 27.7 0 000 27.7v944.6a27.7 27.7 0 0027.7 27.7h944.6a27.7 27.7 0 0027.7-27.7V27.7A27.7 27.7 0 00972.3 0zm-27.7 944.6H55.4V55.4h889.2v889.2z" />
                        </svg>
                        <svg v-if="tag.name == 'Sky'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"
                            class="w-10 inline fill-current stroke-current">
                            <path
                                d="M972.3 0H27.7C12.4 0 0 12.4 0 27.7v944.6c0 15.3 12.4 27.7 27.7 27.7h944.6c15.3 0 27.7-12.4 27.7-27.7V27.7C1000 12.4 987.6 0 972.3 0zm-27.7 944.6H55.4V55.4h889.2v889.2z" />
                            <path
                                d="M480 453.2c43.9 0 79.7-35.7 79.7-79.7 0-43.9-35.7-79.7-79.7-79.7-43.9 0-79.7 35.7-79.7 79.7s35.8 79.7 79.7 79.7zm0-124.7c24.8 0 45 20.2 45 45s-20.2 45-45 45-45-20.2-45-45 20.2-45 45-45zM184.5 706.1h535.3c9.6 0 17.3-7.8 17.3-17.3s-7.8-17.3-17.3-17.3H533.5c-5.9-30.7-26.9-56.5-55.9-68.6-.6-3.1-1.3-6.1-2.1-9H815.5c9.6 0 17.3-7.8 17.3-17.3 0-2.3-.5-4.5-1.3-6.5.6-4.4.9-8.9.9-13.4 0-51.6-41.5-93.7-92.9-94.6-19.2-30.3-52.7-48.8-88.8-48.8-50 0-93.2 35.8-103.1 84.2-25.9 11.1-44.9 34.4-50.7 61.8h-30.8c-2.3 0-4.4.4-6.4 1.2-20.7-30.2-55.5-49.8-94.1-49.8-39.5 0-76 20.4-96.8 53.7-56 .5-101.5 46.3-101.5 102.4 0 5 .4 10 1.1 15-.9 2.1-1.4 4.5-1.4 6.9.2 9.6 7.9 17.4 17.5 17.4zm380.4-178.2c8.7-3 14.8-10.4 16.1-19.4 4.8-34.6 34.9-60.6 69.8-60.6 25.7 0 49.3 14 61.8 36.5 4.5 8.1 13.1 12.8 22.3 12.4 1 0 1.9-.1 2.9-.1 33.1 0 60 26.9 60 60 0 .9 0 1.7-.1 2.6H533c5.3-14.6 16.9-26.3 31.9-31.4zm-291.2 71.2c9.1.5 17.9-4.4 22.3-12.4 13.9-25.6 40.7-41.4 69.7-41.4 39.5 0 73.4 29.5 78.6 68.6 1.2 9.1 7.4 16.6 16.1 19.5 18 6 31.8 20.4 37.4 38H202.3c-.1-1.5-.2-3.1-.2-4.6 0-38.6 32.4-69.9 71.6-67.7z" />
                        </svg>
                        <svg v-if="tag.name == 'Backplate'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"
                            class="w-10 inline fill-current stroke-current">
                            <path
                                d="M635.6 276.9c36 0 65.3 29.3 65.3 65.3 0 9.6 7.8 17.3 17.3 17.3s17.3-7.8 17.3-17.3c0-36 29.3-65.3 65.3-65.3 9.6 0 17.3-7.8 17.3-17.3s-7.8-17.3-17.3-17.3c-34.3 0-64.6 17.4-82.6 43.8-18-26.4-48.3-43.8-82.6-43.8-9.6 0-17.3 7.8-17.3 17.3s7.8 17.3 17.3 17.3z" />
                            <path
                                d="M972.3 0H27.7A27.7 27.7 0 000 27.7v944.6a27.7 27.7 0 0027.7 27.7h944.6a27.7 27.7 0 0027.7-27.7V27.7A27.7 27.7 0 00972.3 0zm-27.7 944.6H55.4V576.8l224.7-297 304.5 283c12 11.1 29.7 12.4 43.2 3.2l85.6-58.9 231.1 249.3v188.2zm0-889.2v639.8L739.9 474.4c-11.6-12.5-31-14.7-45-5l-86 59.2-306.1-284.5a34.3 34.3 0 00-26.6-9.1c-9.7.9-18.7 5.8-24.6 13.6L55.4 507.9V55.4h889.2z" />
                        </svg>
                        <svg v-if="tag.name == 'Ground'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"
                            class="w-10 inline fill-current stroke-current">
                            <path
                                d="M972.3 0H27.7A27.7 27.7 0 000 27.7v944.6a27.7 27.7 0 0027.7 27.7h944.6a27.7 27.7 0 0027.7-27.7V27.7A27.7 27.7 0 00972.3 0zM425.2 785.4l519.3-114.3v271.1L425.2 785.4zM55.4 548.3l77.1 13.9c.1 0 .2 0 .3.1l611.5 110.3-400.7 88.2-288.3-87V548.3zM505 438.8c0-49 39.9-88.9 88.9-88.9 49 0 88.9 39.9 88.9 88.9 0 5-.4 10-1.3 14.9l-169 20.8a88.92 88.92 0 01-7.5-35.7zm109.5 65.1l99.4-12.2 230.7-28.4v165.4l-98.1 21.6c-2.1-1.2-4.4-2.1-6.9-2.6L275 545.7l339.5-41.8zM944.6 55.4v365.9l-220.7 27.2c.2-3.2.4-6.4.4-9.7 0-71.9-58.5-130.4-130.4-130.4s-130.4 58.5-130.4 130.4c0 14 2.3 27.8 6.6 41l-332.9 41-81.7-14.7V55.4h889.1zM55.4 717.2l753.2 227.4H55.4V717.2z" />
                        </svg>
                        <svg v-if="tag.name == 'People'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"
                            class="w-10 inline fill-current stroke-current">
                            <path class="st0"
                                d="M500 355.5c-52.9 0-95.9-43-95.9-95.9s43-95.9 95.9-95.9 95.9 43 95.9 95.9-43 95.9-95.9 95.9zm0-157.8c-34.1 0-61.9 27.8-61.9 61.9s27.8 61.9 61.9 61.9c34.1 0 61.9-27.8 61.9-61.9s-27.8-61.9-61.9-61.9zM563.5 836.3c-9.4 0-17-7.6-17-17V683.5h46.7c7 0 12.7-5.7 12.7-12.7V443.4c0-7-5.7-12.7-12.7-12.7H406.8c-7 0-12.7 5.7-12.7 12.7v227.4c0 7 5.7 12.7 12.7 12.7h46.7v135.8c0 9.4-7.6 17-17 17s-17-7.6-17-17V717.5h-12.7c-25.7 0-46.7-20.9-46.7-46.7V443.4c0-25.7 20.9-46.7 46.7-46.7h186.4c25.7 0 46.7 20.9 46.7 46.7v227.4c0 25.7-20.9 46.7-46.7 46.7h-12.7v101.8c0 9.4-7.6 17-17 17z" />
                            <path id="XMLID_24_" class="st0"
                                d="M972.3 0H27.7A27.7 27.7 0 000 27.7v944.6a27.7 27.7 0 0027.7 27.7h944.6a27.7 27.7 0 0027.7-27.7V27.7A27.7 27.7 0 00972.3 0zm-27.7 944.6H55.4V55.4h889.2v889.2z" />
                        </svg>

                        <span class="text-sm absolute mt-10">{{ tag.name }}</span>
                    </div>
                </div>

                <div class="mt-10 text-sm">
                    <div class="border-t border-b py-1">
                        <h3 class="text-lg cursor-pointer" @click="settingsOpened = !settingsOpened">
                            <span class="align-middle inline-block">
                                <svg v-if="settingsOpened" xmlns="http://www.w3.org/2000/svg"
                                    class="h-6 w-6 inline-block align-middle" fill="none" viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M19 9l-7 7-7-7" />
                                </svg>
                                <svg v-if="!settingsOpened" xmlns="http://www.w3.org/2000/svg"
                                    class="h-6 w-6 inline-block align-middle" fill="none" viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M9 5l7 7-7 7" />
                                </svg>
                            </span>
                            <span class="align-middle inline-block">Settings</span>
                        </h3>

                        <!-- settigs body -->
                        <div class="py-2" v-if="settingsOpened">
                            <span class="inline">
                                <h3 class="inline">Resolution</h3>

                                <span class="text-sm text-gray">
                                    <span>(min width: {{ resolution }}px)</span>
                                </span>
                            </span>
                            <div class="mt-2 mx-4 pb-10">

                                <Slider v-model="resolution" :min="0" :max="10000" :step="500" :tooltip="'always'"
                                    tooltipPosition="bottom" />

                            </div>

                            <div class="flex flex-row items-center justify-between">
                                <!-- <div class="p-2">Thumbs size</div> -->

                                <div class="w-full grid grid-cols-3 gap-2 p-2" style="max-width: 150px">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"
                                        class="w-full p-1 mr-3 cursor-pointer" :class="getThumbsIconClasses(200)"
                                        @click="changeThumbsSize(200)">
                                        <path
                                            d="M744.8 281.9H1000V0H718.1v281.9h26.7zm212.7-42.5H907l50.5-50.5v50.5zm0-103.9L853.6 239.4h-66.3L957.5 69.2v66.3zm-196.9-93h50.5L760.6 93V42.5zm0 103.9L864.5 42.5h66.3L760.6 212.7v-66.3zM385.8 281.9H641V0H359.1v281.9h26.7zm212.6-42.5h-50.5l50.5-50.5v50.5zm0-103.9L494.5 239.4h-66.3L598.4 69.2v66.3zm-196.8-93h50.5L401.6 93V42.5zm0 103.9L505.5 42.5h66.3L401.6 212.7v-66.3zM26.7 281.9H281.9V0H0v281.9h26.7zm212.7-42.5h-50.5l50.5-50.5v50.5zm0-103.9L135.5 239.4H69.2L239.4 69.2v66.3zm-196.9-93H93L42.5 93V42.5zm0 103.9L146.4 42.5h66.3L42.5 212.7v-66.3zM744.8 640.9H1000V359H718.1v281.9h26.7zm212.7-42.5H907l50.5-50.5v50.5zm0-103.9L853.6 598.4h-66.3l170.2-170.2v66.3zm-196.9-92.9h50.5l-50.5 50.5v-50.5zm0 103.9l103.9-103.9h66.3L760.6 571.7v-66.2zM385.8 640.9H641V359H359.1v281.9h26.7zm212.6-42.5h-50.5l50.5-50.5v50.5zm0-103.9L494.5 598.4h-66.3l170.2-170.2v66.3zm-196.8-92.9h50.5l-50.5 50.5v-50.5zm0 103.9l103.9-103.9h66.3L401.6 571.7v-66.2zM26.7 640.9H281.9V359H0v281.9h26.7zm212.7-42.5h-50.5l50.5-50.5v50.5zm0-103.9L135.5 598.4H69.2l170.2-170.2v66.3zM42.5 401.6H93l-50.5 50.5v-50.5zm0 103.9l103.9-103.9h66.3L42.5 571.7v-66.2zM744.8 1000H1000V718.1H718.1V1000h26.7zm212.7-42.5H907l50.5-50.5v50.5zm0-103.9L853.6 957.5h-66.3l170.2-170.2v66.3zm-196.9-93h50.5l-50.5 50.5v-50.5zm0 103.9l103.9-103.9h66.3L760.6 930.8v-66.3zM385.8 1000H641V718.1H359.1V1000h26.7zm212.6-42.5h-50.5l50.5-50.5v50.5zm0-103.9L494.5 957.5h-66.3l170.2-170.2v66.3zm-196.8-93h50.5l-50.5 50.5v-50.5zm0 103.9l103.9-103.9h66.3L401.6 930.8v-66.3zM26.7 1000H281.9V718.1H0V1000h26.7zm212.7-42.5h-50.5l50.5-50.5v50.5zm0-103.9L135.5 957.5H69.2l170.2-170.2v66.3zm-196.9-93H93l-50.5 50.5v-50.5zm0 103.9l103.9-103.9h66.3L42.5 930.8v-66.3z" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"
                                        class="w-full p-1 mr-3 cursor-pointer" :class="getThumbsIconClasses(250)"
                                        @click="changeThumbsSize(250)">
                                        <path
                                            d="M428.3 0H0v461.4h461.4V0h-33.1zM42.5 42.5H93L42.5 93V42.5zm0 103.9L146.4 42.5h66.3L42.5 212.7v-66.3zm0 119.7L266.1 42.5h66.3L42.5 332.4v-66.3zm376.4 152.8h-50.5l50.5-50.5v50.5zm0-103.9L315 418.9h-66.3l170.2-170.2V315zm0-119.7L195.3 418.9H129L418.9 129v66.3zm0-119.7L75.6 418.9H42.5v-33.1L385.8 42.5h33.1v33.1zM966.9 0H538.6v461.4H1000V0h-33.1zM581.1 42.5h50.5L581.1 93V42.5zm0 103.9L685 42.5h66.3L581.1 212.7v-66.3zm0 119.7L804.7 42.5H871L581.1 332.4v-66.3zm376.4 152.8H907l50.5-50.5v50.5zm0-103.9L853.6 418.9h-66.3l170.2-170.2V315zm0-119.7L733.9 418.9h-66.3L957.5 129v66.3zm0-119.7L614.2 418.9h-33.1v-33.1L924.4 42.5h33.1v33.1zM428.3 538.6H0V1000h461.4V538.6h-33.1zM42.5 581.1H93l-50.5 50.5v-50.5zm0 103.9l103.9-103.9h66.3L42.5 751.3V685zm0 119.7l223.6-223.6h66.3L42.5 870.9v-66.2zm376.4 152.8h-50.5l50.5-50.5v50.5zm0-103.9L315 957.5h-66.3l170.2-170.2v66.3zm0-119.7L195.3 957.5H129l289.9-289.9v66.3zm0-119.7L75.6 957.5H42.5v-33.1l343.3-343.3h33.1v33.1zM966.9 538.6H538.6V1000H1000V538.6h-33.1zm-385.8 42.5h50.5l-50.5 50.5v-50.5zm0 103.9L685 581.1h66.3L581.1 751.3V685zm0 119.7l223.6-223.6H871L581.1 870.9v-66.2zm376.4 152.8H907l50.5-50.5v50.5zm0-103.9L853.6 957.5h-66.3l170.2-170.2v66.3zm0-119.7L733.9 957.5h-66.3l289.9-289.9v66.3zm0-119.7L614.2 957.5h-33.1v-33.1l343.3-343.3h33.1v33.1z" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"
                                        class="w-full p-1 mr-3 cursor-pointer" :class="getThumbsIconClasses(350)"
                                        @click="changeThumbsSize(350)">
                                        <path
                                            d="M614.2 0H0v641H641V0h-26.8zM42.5 42.5H93L42.5 93V42.5zm0 103.9L146.4 42.5h66.3L42.5 212.7v-66.3zm0 119.7L266.1 42.5h66.3L42.5 332.4v-66.3zm0 119.7L385.8 42.5h66.3L42.5 452.1v-66.3zm0 119.7l463-463h66.3L42.5 571.8v-66.3zm555.9 93h-50.5l50.5-50.5v50.5zm0-103.9L494.5 598.5h-66.3l170.2-170.2v66.3zm0-119.7L374.9 598.5h-66.3l289.9-289.9-.1 66.3zm0-119.7L255.2 598.5h-66.3l409.6-409.6-.1 66.3zm0-119.7l-463 463H69.2L598.4 69.2v66.3zM42.5 1000v-15.7l223.6-223.6h66.3L93 1000h53.4l239.3-239.3H452L212.7 1000h53.4l239.3-239.3h66.3L332.4 1000h53.4l212.6-212.6v66.3L452.1 1000h53.4l92.9-92.9v66.3l-26.7 26.7h69.1V718.3H0V1000h42.5zm0-239.4H93l-50.5 50.5v-50.5zm0 104l103.9-103.9h66.3L42.5 930.8v-66.2zM1000 930.8l-69.1 69.2h53.4l15.7-15.8zM1000 811.2L811.2 1000h53.4L1000 864.6zM760.6 1000v-15.7l223.6-223.6h15.8v-42.5H718.1V1000h42.5zm0-239.4h50.5l-50.5 50.5v-50.5zm0 104l103.9-103.9h66.3L760.6 930.8v-66.2zM811.1 641H1000v-69.2l-26.7 26.7H907l93-93v-53.4L853.6 598.5h-66.3L1000 385.8v-53.4L760.6 571.8v-66.3L1000 266.1v-53.4L760.6 452.1v-66.3L1000 146.4V93L760.6 332.4v-66.3L984.2 42.5h15.8V0H718.1v641h93zM760.6 42.5h50.5L760.6 93V42.5zm0 103.9L864.5 42.5h66.3L760.6 212.7v-66.3z" />
                                    </svg>
                                </div>

                                <button @click="shuffle()" class="
                    bg-gray-400
                    hover:bg-gray-500
                    focus:outline-none
                    text-gray-800
                    xfont-bold
                    py-1
                    px-2
                    rounded
                    mx-2
                    ml-5
                  ">
                                    Shuffle
                                </button>
                            </div>
                            <div class="flex items-center mx-2">
                                <label class="block">
                                    <input class="mr-2 leading-tight" type="checkbox" id="free" v-model="free"
                                        value="true" />
                                    <span class="text-sm"> Only free </span>
                                </label>
                            </div>
                        </div>
                        <!-- settings body -->
                    </div>

                    <div v-if="categoryId" class="my-5 tags" style="max-height: calc(100vh - 21rem); overflow-y: auto">
                        <h3 class="text-lg mb-5">Tags</h3>

                        <div v-for="tag in tagsFromCurrentCategory" v-bind:key="tag.id">
                            <div v-if="tag.rootId == categoryId">
                                <div v-if="tag.depth && tag.depth == 1 && !tag.isLeaf" class="mt-4 mb-2">
                                    <h3 class="text-lg font-bold">{{ tag.name }}</h3>
                                </div>
                                <div v-if="tag.isLeaf || (tag.depth && tag.depth == 2)" class="block ml-4">
                                    <label :for="'tags_' + tag.id" class="cursor-pointer"
                                        :class="depthOffsetClass(tag.depth)">
                                        <input type="checkbox" v-model="selectedTags" :value="tag.id"
                                            :id="'tags_' + tag.id" class="leading-tight" />
                                        {{ tag.name }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- sidebar -->
        </div>

        <div v-if="categorySelected" class="flex flex-col flex-1 p-6 pt-0 flex-order-2">
            <!-- lg:w-2/3 xl:w-4/5 -->
            <photo-grid-horizontal :photos-url="photosJsonUrl" :tags="selectedTags" :category-id="categoryId"
                :resolution="resolution" :thumbs-size="thumbsSize" :free="free" :order="order"
                :open-as-detail="openedAsDetail" @closeGallery="onCloseGalery"></photo-grid-horizontal>
        </div>
    </div>
</template>

<script>
import Slider from '@vueform/slider/dist/slider.vue2.js'
import '@vueform/slider/themes/default.css';
import PhotoGridHorizontal from './PhotoGridHorizontal.vue';

export default {
    components: {
        Slider,
        PhotoGridHorizontal
    },
    props: {
        categorySlug: String,
        photoSlug: String,
        startingCategory: {
            type: Number,
            required: false,
            default: () => 0,
        },
        selectedTagSlugs: Array,
    },
    data() {
        return {
            categoryName: null,
            settingsOpened: false,
            resolution: 0,
            free: false,
            invertedSlider: (dotPos) => [[dotPos[0], 100]],
            resolutionFormatter: "{value}px",
            thumbsSize: 250,
            order: "desc",
            tags: [],
            selectedTags: [],
            categoryId: this.startingCategory,
            categorySelected: false,
            openedAsDetail: false,
            photosJsonUrl: "/photos-json",
        };
    },

    async mounted() {
        this.openedAsDetail = !!this.$route.params.photoSlug;
        this.init();
    },

    computed: {
        rootTags() {
            return this.tags.filter((tag) => !tag.depth);
        },
        tagsFromCurrentCategory() {
            return this.tags.filter((tag) => tag.rootId == this.categoryId);
        },
    },
    methods: {
        async init() {
            this.categorySelected = false;
            await this.fetchTags();

            const routeCategory = this.rootTags.filter(
                (tag) => tag.name.toLowerCase() == this.$route.params.categorySlug
            )[0];

            // select category if presend in url
            if (routeCategory) {
                this.categoryId = routeCategory.id;
                this.categoryName = routeCategory.name;
            }
            this.categorySelected = true; // 0 or specific

            // console.log("mounted slugs: " + this.$route.params.selectedTagSlugs);
            // console.log(this.$route.params.selectedTagSlugs.split("+"))
            // console.log(this.tags)

            // select tags from url
            if (this.$route.params.selectedTagSlugs) {
                this.selectedTags = this.tags
                    .filter(
                        (tag) =>
                            tag.rootId == this.categoryId &&
                            this.$route.params.selectedTagSlugs.split("+").includes(tag.slug)
                    )
                    .map((tag) => tag.id);
            }

            this.photosJsonUrl = !!this.photoSlug
                ? `/photo-detail/${this.photoSlug}`
                : "/photos-json";
        },

        changeThumbsSize(size) {
            this.thumbsSize = size;
        },
        getThumbsIconClasses(height) {
            if (this.thumbsSize == height)
                return "border-t-2 border-solid border-green bg-gray-300";
        },
        async fetchTags() {
            const { data } = await axios.get("/photo-tags");
            this.tags = Object.values(data);

            //   console.log(this.rootTags.filter(tag => tag.name == 'Nature')[0].id )

            // this.$route.params.categorySlug
            //    this.rootTags.map(tag => console.log(tag.name))
            // console.log(this.$route.params.categorySlug)
        },
        selectCategory(id, name) {
            console.log(`selecting category ${id}`);
            this.categoryId = id;
            this.categoryName = name;
            this.selectedTags = [];
            this.updateUrl();
            window.scrollTo(0, 0);
        },
        activeCategoryClasses(id) {
            if (id == this.categoryId)
                return "border-t-2 border-solid border-green bg-gray-300";
        },
        depthOffsetClass(depth) {
            return `ml-${4 * (depth - 2)}`;
        },
        shuffle() {
            this.order = "";
            this.$nextTick(() => {
                this.order = "random";
            });
        },
        updateUrl() {
            console.log("updateUrl called");
            var url = "/photos";
            if (this.categoryName)
                url += `/category/${this.categoryName.toLowerCase()}`;
            console.log(this.categoryName);

            if (this.selectedTags.length > 0) {
                const selectedTagsWithMeta = this.tags.filter((tag) =>
                    this.selectedTags.includes(tag.id)
                );
                url += `/filter/${selectedTagsWithMeta
                    .map((tag) => tag.slug)
                    .join("+")}`;
                //   console.log(this.selectedTags);
            }

            if (this.$route.fullPath != url) {
                this.$router.push({
                    path: url,
                });
                console.log("url update pushed to router");
            }

            // window.history.replaceState("", "", $url);
        },

        onCloseGalery() {
            // redirect from photo detail to photo main category (needs to rerender the components)
            if (this.openedAsDetail) {
                this.openedAsDetail = false;

                // window.location = `/photos/category/${this.photoSlug.split("-")[1]}`;
                this.$router.push({
                    path: `/photos/category/${this.photoSlug.split("-")[1]}`,
                });

                this.init();
                return;
            }
            // closing normal galery - returns to filter
            this.updateUrl();
            // window.location.assign(`/photos/category/${this.categorySlug}`);
        },
    },
    watch: {
        selectedTags(newTags, oldTags) {
            window.scrollTo(0, 0);
            this.updateUrl();
        },
    },
};
</script>



<style>
/* width */
.tags::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.tags::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.tags::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.tags::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
