<template>
    <div class="flex flex-col flex-1">
        <div class="
        flex flex-row flex-wrap
        sm:flex-no-wrap
        pt-2
        pb-1
        text-sm text-gray-700
        items-center
        sticky
      ">
            <div class="p-2">
                <div class="">
                    {{ paginator ? paginator.total : 0 }}
                    {{ paginator && paginator.total == 1 ? "item" : "items" }}
                </div>
            </div>
        </div>

        <div class="flex flex-row flex-wrap">
            <div v-for="(photo, $index) in photos" :key="$index" class="inline-block p-1" :class="getThumbsClasses">
                <div :id="photo.slug" class="hidden relative" style="padding: 0">
                    <div class="flex flex-col md:flex-row">
                        <div v-if="photo.isPremium" class="absolute z-10 p-2 right-0 top-0 opacity-75 text-gray">
                            <i class="fas fa-star opacity-half" title="Premium"></i>
                        </div>

                        <zoomable-image v-if="
                            selectedSlide != null &&
                            (selectedSlide == $index || selectedSlide + 1 == $index)
                        " :src="photo.previewUrl"
                            :zoomSrc="selectedSlide != null && selectedSlide == $index ? photo.waterUrl : ''"
                            :width="photo.width" :height="photo.height" :zoomDisabled="zoomDisabled"></zoomable-image>

                        <!-- photo sidebar -->
                        <div>
                            <div class="flex flex-col h-full p-4 w-full md:w-48">
                                <div class="flex-1 mb-2">
                                    <div class="my-2">
                                        <div class="text-sm font-bold text-gray-700">
                                            Resolution
                                        </div>
                                        <div class="text-xs px-2">
                                            {{ photo.width }}x{{ photo.height }} px
                                        </div>
                                    </div>

                                    <div class="my-2">
                                        <div class="text-sm font-bold text-gray-700">File size</div>
                                        <div class="text-xs px-2">{{ photo.size }}</div>
                                    </div>

                                    <div class="my-2">
                                        <div class="text-sm font-bold text-gray-700">File type</div>
                                        <div class="text-xs px-2">{{ photo.mime }}</div>
                                    </div>

                                    <div v-if="false && photo.author" class="my-2">
                                        <div class="text-sm font-bold text-gray-700">Author</div>
                                        <div class="text-xs px-2">{{ photo.author }}</div>
                                    </div>

                                    <div class="my-2">
                                        <div class="text-sm font-bold text-gray-700 my-1">Tags</div>
                                        <div class="flex flex-wrap">
                                            <span v-for="tag in photo.tags" v-bind:key="tag.id" class="
                          inline-block
                          rounded
                          px-1
                          text-xs text-gray-800
                          bg-gray-300
                          mr-1
                          mb-1
                          no-underline
                        ">
                                                #{{ tag }}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="photo.isPremium" class="text-sm text-gray-800 my-2">
                                    This is a premium photo available only to our
                                    <a href="/user/subscription" target="_blank">subscribers</a>.
                                </div>
                                <div v-if="!photo.isPremium" class="text-sm text-gray-800 my-2">
                                    Provided for <span class="text-green font-bold">free</span> by
                                    FlyingArchitecture.
                                </div>

                                <div class="">
                                    <a :href="photo.downloadUrl" target="_blank" class="btn btn--green text-center">
                                        Download
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- end photo sidebar -->
                    </div>
                </div>
                <!-- </v-modal> -->

                <!-- This will be the popover target (for the events and position) -->
                <div class="
            photo-detail
            relative
            block
            overflow-hidden
            shadow
            cursor-pointer
          " :style="{ height: thumbsSize + 'px' }" :data-src="'#' + photo.slug" :data-slug="photo.slug"
                    fancybox="photos">
                    <div v-if="!photo.isPremium" class="
              absolute
              right-0
              top-0
              transform
              rotate-45
              translate-x-1/2
              -translate-y-1/2
              w-20
              flex flex-col
              items-stretch
            ">
                        <div class="
                bg-green-700
                shadow-sm
                py-0
                mt-12
                text-center text-xs text-gray-200
                font-bold
                uppercase
              ">
                            Free
                        </div>
                    </div>

                    <img data-sizes="auto" :width="photo.width" :height="photo.height" :data-srcset="genSrcset(photo)"
                        :src="photo.thumbUrl" :data-src="photo.thumbUrl" :alt="photo.tags.join(', ')" class="
              lazyload
              block
              h-full
              w-auto
              object-cover
              bg-gray-400
              text-transparent
            " />
                </div>
            </div>
        </div>

        <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler" ref="infiniteLoading">
            <template slot="no-more">You've seen it all :)</template>
        </infinite-loading>
    </div>
</template>

<style>
.v--modal-overlay {
    background: rgba(0, 0, 0, 0.7);
}
</style>



<script>
// import the component
import qs from "qs";
import { Fancybox as Fancy4 } from "@fancyapps/ui";
import ZoomableImage from "./ZoomableImage.vue";

// prevent collisions with fancybox 3 in other parts of the site
Fancy4.unbind("[data-fancybox]");
Fancy4.defaults.Hash = false;

window.F4 = Fancy4;

export default {
    components: {
        "zoomable-image": ZoomableImage,
    },
    props: {
        photosUrl: String,
        order: String,
        tags: {
            type: Array,
            default: () => [],
        },
        categoryId: {
            type: Number,
            required: false,
            default: 0,
        },
        resolution: {
            type: Number,
            default: () => 0,
        },
        thumbsSize: {
            type: Number,
            default: () => 150, //px
        },
        free: {
            type: Boolean,
            default: () => false,
        },
        openAsDetail: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            photos: [],
            page: 1,
            infiniteId: +new Date(),
            paginator: null,
            selectedSlide: null,
            loading: false,
            zoomDisabled: false,
            // thumbsSize: 25, //vh
            // free: false,
            // order: 'desc',
        };
    },

    mounted() {
        this.startFancybox();
    },

    computed: {
        // a computed getter
        getThumbsClasses() {
            return "";
        },
    },

    methods: {
        startFancybox() {
            console.log("Starting fancybox");
            return Fancy4.bind("[fancybox]", {
                groupAll: true,
                infinite: false,

                on: {
                    //   "*": (event, fancybox, slide) => {
                    //     console.log(`event: ${event}`);
                    //   },
                    "Carousel.selectSlide": (event, fancybox, slide) => {
                        this.loading = false;

                        this.selectedSlide = slide.index;

                        console.log(`event: ${event}`);
                        console.log(slide);

                        this.$router
                            .replace({ path: `/photos/${slide.slug}` })
                            .catch(() => { });
                        //window.history.replaceState("", "", `/photos/${slide.slug}`);

                        //   console.log(slide)
                        if (slide.index > this.photos.length - 2) {
                            this.loadNextPage(this.$refs.infiniteLoading.stateChanger);
                            // Fancybox.getInstance().close();
                            window.scrollTo(0, document.body.scrollHeight);
                        }
                    },
                    'Carousel.Panzoom.touchMove': (event, fancybox, slide) => {
                        this.zoomDisabled = true;
                    },
                    "Carousel.settle": (event, fancybox, slide) => {
                        this.zoomDisabled = false;
                    },
                    close: (event, fancybox) => {
                        this.selectedSlide = null;
                        this.$emit("closeGallery");
                    },
                },
            });
        },

        zoom(e) {
            if (!e.target.getAttribute("zoomLoaded")) {
                this.loading = true;
                e.target.src = e.target.getAttribute("zoomSrc");
                e.target.setAttribute("zoomLoaded", 1);
            }
        },

        zoomLoaded() {
            this.loading = false;
        },

        getModalName(photoId) {
            return "photo-" + photoId;
        },

        infiniteHandler($state) {
            this.loadNextPage($state);
        },

        loadNextPage($state) {
            console.log(`Loading page ${this.page}`);
            axios
                .get(this.photosUrl, {
                    params: {
                        page: this.page,
                        "tags[]": this.tags,
                        category: this.categoryId,
                        minRes: this.resolution,
                        order: this.order ?? "desc",
                        free: +this.free,
                    },
                    paramsSerializer: function (params) {
                        return qs.stringify(params, { arrayFormat: "repeat" });
                    },
                })
                .then(({ data }) => {
                    if (data.data.length) {
                        this.page += 1;
                        this.photos.push(...data.data);
                        this.paginator = data.meta.pagination;
                        $state.loaded();
                        this.showGallery();
                    } else {
                        $state.complete();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        filterChanged() {
            this.page = 1;
            this.photos = [];
            this.paginator = null;
            this.infiniteId += 1;
        },

        getThumbsIconClasses(height) {
            if (this.thumbsSize == height)
                return "border-t-2 border-solid border-green bg-gray-300";
        },
        changeThumbsSize(height) {
            this.thumbsSize = height;
        },
        genSrcset(photo) {
            return `${photo.previewUrl} 1000w, ${photo.thumbUrl} 500w`;
            // http://fa.test/storage/images/207/responsive-images/boy1___responsive_1246_1799.jpg 1246w, http://fa.test/storage/images/207/responsive-images/boy1___responsive_881_1272.jpg 881w, http://fa.test/storage/images/207/responsive-images/boy1___responsive_623_899.jpg 623w, http://fa.test/storage/images/207/responsive-images/boy1___responsive_440_635.jpg 440w
        },

        showGallery() {
            if (this.openAsDetail) {
                this.$nextTick(function () {
                    document.querySelector("[fancybox]").click();
                });
            }
        },
    },

    watch: {
        tags: function (newTags, oldTags) {
            this.filterChanged();
        },
        resolution: function (newTags, oldTags) {
            this.filterChanged();
        },
        free: function (newTags, oldTags) {
            this.filterChanged();
        },
        order: function (newTags, oldTags) {
            this.filterChanged();
        },
        categoryId: function (newTags, oldTags) {
            console.log("cat is changed");
            this.filterChanged();
        },
    },
};
</script>
