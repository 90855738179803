<template>
  <div
    class="flex flex-row items-center justify-center bg-gray-800 relative"
    style="min-width: 500px"
  >
    <!-- Loading anim -->
    <div
      v-if="loading"
      class="absolute w-full h-full flex justify-center items-center"
    >
      <svg
        class="block w-20 white z-50"
        version="1.1"
        id="L9"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enable-background="new 0 0 0 0"
        xml:space="preserve"
      >
        <path
          fill="#fff"
          d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="1s"
            from="0 50 50"
            to="360 50 50"
            repeatCount="indefinite"
          ></animateTransform>
        </path>
      </svg>
    </div>
    <!-- Loading anim -->

    <div
      v-if="!zoomLoading"
      class="absolute m-1 z-50 opacity-75 bg-gray bottom-0 right-0"
    >
      <span data-v-8132c852="" class="iiz__btn iiz__hint"></span>
    </div>

    <img
      :src="src"
      alt=""
      @click="handleClick"
      @load="onLoad"
      style="max-height: 78vh"
      class="
        block
        bg-gray-700
        relative
        max-h-full
        bg-gray-800
        h-full
        w-auto
        object-scale-down
      "
      :width="width"
      :height="height"
      ref="image"
    />
  </div>
</template>

<script>
export default {
  props: {
    width: Number,
    height: Number,
    src: {
      type: String,
      default: () => "",
    },
    zoomSrc: String,
    zoomDisabled: Boolean,
  },
  data() {
    return {
      loading: true,
      zoomLoading: false,
      drag: false,
      click: false,
    };
  },
  mounted() {},
  methods: {
    zoom() {
      if (!this.zoomLoading) {
        this.loading = true;
        this.$refs.image.setAttribute("src", this.zoomSrc);
        this.zoomLoading = true;
      }
    },
    onLoad() {
      this.loading = false;
    },
    handleClick() {
      if (!this.zoomDisabled) {
        this.zoom();
      }
    },
  },
};
</script>
