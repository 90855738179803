/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Vue from "vue";
// import Vue from "vue/dist/vue.js";

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */
Vue.component("Dropdown", () => import("./components/Dropdown.vue"));
Vue.component("ProductGrid", () => import("./components/ProductGrid.vue"));
Vue.component("ProductGridInfinite", () =>
    import("./components/ProductGridInfinite.vue")
);
Vue.component("CategoryTree", () => import("./components/CategoryTree.vue"));
Vue.component("cartTable", () => import("./components/CartTable.vue"));
Vue.component("ButtonAddToCart", () =>
    import("./components/ButtonAddToCart.vue")
);
Vue.component("cartIcon", () => import("./components/CartIcon.vue"));
Vue.component("CartWrapper", () => import("./components/CartWrapper.vue"));

Vue.component("BraintreeForm", () => import("./components/BraintreeForm.vue"));
Vue.component("PhotoTags", () => import("./components/PhotoTags.vue"));
Vue.component("PhotoGrid", () => import("./components/PhotoGrid.vue"));
Vue.component("PopupBanner", () => import("./components/PopupBanner.vue"));
Vue.component("CustomTippy", () => import("./components/CustomTippy.vue"));
Vue.component("ProductsFilter", () =>
    import("./components/ProductsFilter.vue")
);
Vue.component("CustomScript", () => import("./components/CustomScript.vue"));
Vue.component("Portfolio", () => import("./components/Portfolio.vue"));
Vue.component("WpMobileMenu", () => import("./components/WpMobileMenu.vue"));
Vue.component("PhotosFilter", PhotosFilter);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import VModal from "vue-js-modal";
import Vreq from "./common/Vreq";
import VueCookies from "vue-cookies";
import InfiniteLoading from "vue-infinite-loading";
import VueLazyload from "vue-lazyload";
import ZoomOnHover from "vue-zoom-on-hover";
import vClickOutside from "v-click-outside";
import VTooltip from "v-tooltip";
import VueRouter from "vue-router";
import VueScrollactive from "vue-scrollactive";
import { Fancybox } from "@fancyapps/ui";
import PhotosFilter from "./components/PhotosFilter.vue";
import { jarallax } from "jarallax";
import "jarallax/dist/jarallax.min.css";
import "./components/cart.js";
import { store } from "./components/store.js";
import "./common/flash.js";

Vue.use(VueScrollactive);
Vue.use(VTooltip);
Vue.use(vClickOutside);
Vue.use(VModal, { componentName: "v-modal" });
Vue.use(Vreq);
Vue.use(VueCookies);
Vue.use(ZoomOnHover);

Vue.use(VueLazyload, {
    lazyComponent: true,
    preLoad: 2.0,
    loading: "/images/logo-notext.png",
});

Vue.use(InfiniteLoading, {
    props: {
        distance: 1500,
    },
});

const router = new VueRouter({
    mode: "history",
    routes: [
        {
            path: "/photos/category/:categorySlug/filter/:selectedTagSlugs",
            component: PhotosFilter,
            props: (route) => ({
                categorySlug: route.params.categorySlug,
                selectedTagSlugs: route.params.selectedTagSlugs.split("+"),
            }),
        },
        {
            path: "/photos/category/:categorySlug",
            component: PhotosFilter,
            props: true,
        },
        {
            path: "/photos/:photoSlug",
            component: PhotosFilter,
            props: true,
        },
        {
            path: "/photos",
            component: PhotosFilter,
            props: true,
        },
        // { path: '/photos', component: null },
    ],
});

Vue.use(VueRouter);

var vm = new Vue({
    el: "#app",
    router,
    data: {
        store: store.state,
    },
});

Fancybox.bind("[data-fancybox]", {
    Toolbar: false,
    contentClick: "zoomToMax",
});

document.addEventListener("DOMContentLoaded", function () {
    document
        .querySelectorAll('[data-fancybox-3-work="gallery"]')
        .forEach(function (element) {
            element.addEventListener("click", function (e) {
                e.preventDefault();
                var slide = {
                    opts: {
                        $orig: element,
                    },
                    $slide: element,
                };
                // Tip: Each event passes useful information within the event object:

                // Object containing references to interface elements
                // (background, buttons, caption, etc)
                // console.info( instance.$refs );

                // Current slide options
                console.info(slide);

                // Clicked element
                // console.info( slide.opts.$orig );

                // Reference to DOM element of the slide
                // console.info( slide.$slide );
            });
        });

    document
        .querySelector(".js-menu-button")
        ?.addEventListener("click", function (e) {
            var mobileMenu = document.querySelector(".mobile-menu");
            var menuButton = document.querySelector(".js-menu-button");
            mobileMenu.style.display =
                mobileMenu.style.display === "none" ? "block" : "none";
            menuButton.classList.toggle("is-opened");
        });

    document
        .querySelectorAll(".js-toggle-nav-dropdown")
        ?.forEach(function (element) {
            element.addEventListener("click", function (e) {
                e.preventDefault();
                var navDropdown = element.nextElementSibling;
                // toggle css class hidden
                navDropdown.classList.toggle("hidden");
                navDropdown.classList.toggle("block");
            });
        });

    document
        .querySelector(".js-toggle-search")
        ?.addEventListener("click", function (e) {
            var search = document.querySelector(".js-search");
            search.style.display =
                search.style.display === "none" ? "block" : "none";
            document.querySelector(".js-search input").focus();
        });

    document.addEventListener("mouseup", function (e) {
        var navDropdown = document.querySelector(".js-nav-dropdown");
        var toggleNav = document.querySelector(".js-toggle-nav-dropdown");
        var search = document.querySelector(".js-search");
        var toggleSearch = document.querySelector(".js-toggle-search");
        if (
            navDropdown &&
            !navDropdown?.contains(e.target) &&
            !toggleNav?.contains(e.target) &&
            !toggleSearch?.contains(e.target) &&
            !search?.contains(e.target)
        ) {
            // navDropdown.style.display = "none";
            navDropdown.classList.add("hidden");
            navDropdown.classList.remove("block");
        }
        if (
            search &&
            !search?.contains(e.target) &&
            !toggleSearch?.contains(e.target)
        ) {
            search.style.display = "none";
        }
    });

    document.querySelectorAll("ul.tabs li").forEach(function (element) {
        element.addEventListener("click", function () {
            var tabId = element.getAttribute("data-tab");

            document.querySelectorAll("ul.tabs li").forEach(function (tab) {
                tab.classList.remove("current");
            });
            document
                .querySelectorAll(".tab-content")
                .forEach(function (content) {
                    content.classList.remove("current");
                });

            element.classList.add("current");
            document.getElementById(tabId).classList.add("current");
        });
    });

    document.querySelectorAll(".js-reply").forEach(function (element) {
        element.addEventListener("click", function (e) {
            e.preventDefault();
            var replyForm =
                element.parentNode.parentNode.querySelector(".reply-form");
            document.querySelectorAll(".reply-form").forEach(function (form) {
                form.style.display = "none";
            });
            replyForm.style.display = "block";
            replyForm.querySelector("textarea").focus();
        });
    });

    document
        .querySelector(".subscribe-form")
        ?.addEventListener("submit", function (e) {
            e.preventDefault();
            console.log(
                document.querySelector("input[name=billing]:checked").value
            );
            document.querySelector("input[name=plan]").value =
                document.querySelector("input[name=billing]:checked").value;
            var subscribeButton = document.getElementById("subscribe-button");
            subscribeButton.disabled = true;
            subscribeButton.classList.add("opacity-50");
            subscribeButton.innerHTML =
                '<i class="fas fa-spinner fa-spin"></i> Working';

            this.submit();
        });

    document.querySelectorAll(".article img").forEach(function (element) {
        var href = element.getAttribute("data-original");
        var link = document.createElement("a");
        link.href = href;
        link.classList.add("block", "my-8");
        link.setAttribute("data-fancybox", "gallery");
        element.parentNode.insertBefore(link, element);
        link.appendChild(element);
    });

    jarallax(document.querySelectorAll(".jarallax"), {
        speed: 0.2,
    });
});

// auto select search input if present - somehow vue captures the focus after loading
window.onload = function () {
    const element = document.querySelector(".js-auto-select");
    if (!element) return;
    element.focus();
    element.select();
};
