import Vue from "vue";

export const cart = Vue.observable({
    items: [],
    totals: {},
    opened: false,
});

export const mutations = {
    addItem(id) {
        console.log("adding item to cart " + id);
        axios
            .post("/cart/add/" + id, { quantity: 1 })
            .then((response) => {
                this.update(response.data.cart, response.data.totals);
            })
            .catch((err) => {
                console.log(err);
            });
        // this.items.push(cartItem);
    },
    update(items, totals) {
        console.log("updating items");

        cart.items = items;
        cart.totals = totals;
    },
    removeItem(id) {
        console.log("removing item from cart " + id);

        axios
            .post("/cart/remove/" + id)
            .then((response) => {
                this.update(response.data.cart, response.data.totals);
            })
            .catch((err) => {
                console.log(err);
            });
    },
    toggleCart() {
        cart.opened = !cart.opened;
    },
    closeCart() {
        cart.opened = false;
    },
};
